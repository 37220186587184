<script setup>
import { ref, onMounted, watch, onUnmounted, onBeforeUnmount } from 'vue'
import lottie from 'lottie-web'
import runningAnimation from './assets/running.json'

import {
  PART_ONE,
  PART_TWO,
  PART_THREE,
  PART_FOUR,
  PART_FIVE
} from './locale'

const lang = ref('en')
const userLanguage = navigator.language.split('-')[0]
const supportedLanguages = ['zh', 'jp', 'kr']

if (supportedLanguages.includes(userLanguage)) {
  lang.value = userLanguage
}

const displayText = ref('')
let index = 0
let isDeleting = false
let isPaused = false

const sportType = () => {
  const sports = PART_ONE[lang.value]?.sports
  
  index = index % sports.length
  const fullText = sports[index]

  const currentLength = displayText.value.length

  if (isDeleting) {
    displayText.value = fullText.substring(0, currentLength - 1)
    if (displayText.value.length === 0) {
      isDeleting = false
      isPaused = false
      index = (index + 1) % sports.length
    }
  } else {
    displayText.value = fullText.substring(0, currentLength + 1)
    if (displayText.value.length === fullText.length) {
      isPaused = true
      setTimeout(() => {
        isPaused = false
        isDeleting = true
        sportType()
      }, 1000)
      return
    }
  }

  const nextDelay = isPaused ? 1000 : (isDeleting ? 20 : 200)
  setTimeout(sportType, nextDelay)
}

const mainContent = ref(null)
const isArrowHidden = ref(false)
const isRunning = ref(false)
const isFinish = ref(false)

const checkScroll = () => {
  if (!mainContent.value) return
  
  const scrollPosition = mainContent.value.scrollTop
  const scrollHeight = mainContent.value.scrollHeight
  const clientHeight = mainContent.value.clientHeight

  isArrowHidden.value = scrollHeight - scrollPosition - clientHeight < 200
  isRunning.value = mainContent.value.scrollTop > 0 && scrollHeight - scrollPosition - clientHeight > 100
  isFinish.value = scrollHeight - scrollPosition - clientHeight <= 100
}

const animationContainer = ref(null)
let animationInstance = null

onMounted(() => {
  document.title = 'Fito'
  sportType()
  if (mainContent.value) {
    mainContent.value.addEventListener('scroll', checkScroll)
    checkScroll()
  }
  animationInstance = lottie.loadAnimation({
    container: animationContainer.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: runningAnimation,
  })
  return {
    animationContainer,
  }
})

onBeforeUnmount(() => {
  if (animationInstance) {
    animationInstance.destroy()
  }
})


onUnmounted(() => {
  if (mainContent.value) {
    mainContent.value.removeEventListener('scroll', checkScroll)
  }

})

watch(lang, () => {
  index = 0
  isDeleting = false
  isPaused = false
  displayText.value = ''
  sportType()
})
</script>

<template>
  <header>
    <div class="red-blur"></div>
    <div class="yellow-blur"></div>
  </header>
  <main ref="mainContent">
    <div class="part one">
      <div class="logo">
        <img alt="logo" src="./assets/logo.png">
        <span>Fito</span>
      </div>
      <div class="title">
        <span class="typing-text">{{ displayText }}</span>
        {{ PART_ONE[lang].title }}
      </div>
      <div class="desc">{{ PART_ONE[lang].desc }}</div>
      <a href="https://apps.apple.com/app/id6596806184"><img class="download-btn" alt="download-btn" src="./assets/download_button.png"></a>
      <img class="screenshot" alt="screenshot" src="./assets/part_one.png">
    </div>
    <div class="part two">
      <div class="title">{{ PART_TWO[lang].title }}</div>
      <div class="desc">{{ PART_TWO[lang].desc }} <img class="sync" alt="sync" src="./assets/sync.png"></div>
      <img class="screenshot" alt="screenshot" src="./assets/part_two.png">
    </div>
    <div class="part three">
      <div class="title">{{ PART_THREE[lang].title }}</div>
      <div class="desc">{{ PART_THREE[lang].desc }}</div>
      <img class="screenshot" alt="screenshot" src="./assets/part_three.png">
    </div>
    <div class="part four">
      <div class="title">{{ PART_FOUR[lang].title }}</div>
      <div class="desc">{{ PART_FOUR[lang].desc }}</div>
      <img class="screenshot" alt="screenshot" src="./assets/part_four.png">
    </div>
    <div class="part five">
      <div class="title">{{ PART_FIVE[lang].title }}</div>
      <a href="https://apps.apple.com/app/id6596806184"><img class="download-btn" alt="download-btn" src="./assets/download_button.png"></a>
    </div>
  </main>
  <footer>
    <div ref="animationContainer" class="draw running" :class="[{ hidden: !isRunning && !isFinish }, { show: isRunning }, { 'delay-hidden': isFinish }]"></div>
    <img class="draw warm-up" alt="warm-up" :class="{ show: !isRunning && !isFinish }" src="./assets/warm_up.png">
    <img class="draw finish" alt="finish" :class="{ 'delay-show': isFinish }" src="./assets/finish.png">
    <img class="finish-line" alt="finish-line" :class="[{ running: isRunning }, { finish: isFinish }]" src="./assets/finish_line.png">
    <div class="line" :class="{ move: isRunning }"></div>
    <div class="copyright">
      <div class="copyright-group1">
        <span><a href="https://verbose-gambler-150.notion.site/f15d593f1dbc4454aae0285fe32f5b36" target="_blank">Privacy Policy</a></span>
        <span><a href="mailto:getfitoapp@gmail.com">Contact Us</a></span>
      </div>
      <div class="copyright-group2">
        <span>©️ Verse Technology Co., Ltd 2024</span>
        <span>ICP备2024076843号-1</span>
      </div>
    </div>
    <div class="floating-arrow" :class="{ hidden: isArrowHidden }">
      <img class="arrow" alt="arrow" src="./assets/floating_arrow.png">
    </div>
  </footer>
</template>

<style>
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: 'Exo 2', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
header {
  position: relative;
  z-index: -1;
  .red-blur, .yellow-blur {
    width: 400px;
    height: 400px;
    position: absolute;
    top: -180px;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(60px);
  }
  .red-blur {
    background-color: #FFE0DF;
    margin-left: -180px;
  }
  .yellow-blur {
    background-color: #FEFAE6;
    margin-left: 180px;
  }
}
main {
  overflow-y: scroll;
  height: calc(100vh - 100px);
  .part {
    .title {
      font-size: 20px;
      font-weight: Bold;
      margin-bottom: 5px;
    }
    .desc {
      font-size: 16px;
      margin-bottom: 30px;
      vertical-align: middle;
    }
    .screenshot {
      width: 320px;
      margin-bottom: 80px;
    }
    .download-btn {
      width: 151px;
      margin: 10px 0 50px;
    }
  }
  .part.one {
    .logo {
      margin: 60px 0 30px;
      img {
        width: 50px;
        border-radius: 12px;
        vertical-align: middle;
      }
      span {
        font-size: 24px;
        line-height: 24px;
        font-weight: Bold;
        vertical-align: middle;
        margin-left: 15px;
      }
    }
    .typing-text {
      position: relative;
      font-size: 20px;
    }
    .typing-text::after {
      content: '';
      position: absolute;
      right: -3px;
      width: 3px;
      height: 18px;
      top: 4px;
      background-color: #F0605C;
      animation: blink 1s infinite;
      border-radius: 2px;
    }
  }
  .part.two .sync {
    width: 100px;
    vertical-align: middle;
  }
  .part.five {
    margin-bottom: 100px;
  }
  a {
    display: block;
  }
}

footer {
  background-color: #FFE8E8;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100px;
  right: 0;

  .copyright {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    color: #6b7280;
    position: absolute;
    bottom: 5px;
    gap: 4px;

    .copyright-group1 {
      justify-content: center;
      margin-bottom: 4px;
      display: flex;
      gap: 15px;
    }

    .copyright-group2 {
      justify-content: center;
      display: flex;
      gap: 15px;
    }

    a {
      color: #6b7280;
      text-decoration: none;

      &:hover {
        color: #F0605C;
        text-decoration: underline;
      }
    }
  }
  .floating-arrow {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    height: 140px;
    background: linear-gradient(0deg, #fff, #fff0);
    transition: .5s;
    pointer-events: none;
    img {
      width: 25px;
      animation: float 2s ease-in-out infinite;
      position: absolute;
      bottom: 20px;
      left: 50%;
      margin-left: -13px;
    }
  }
  .show {
    opacity: 1 !important;
  }
  .hidden {
    opacity: 0 !important;
  }
  .delay-show {
    animation: show .5s forwards;
    animation-delay: .5s;
  }
  .delay-hidden {
    animation: hidden .5s forwards;
    animation-delay: .5s;
    transition: .5s;
    left: 50% !important;
    margin-left: -40px;
  }
  .draw {
    z-index: 10;
    position: fixed;
    left: 0;
    bottom: 40px;
    opacity: 0;
    &.running {
      width: 100px;
      height: 100px;
      opacity: 1;
    }
    &.warm-up {
      width: 60px;
      margin: 0 0 15px 20px;
    }
    &.finish {
      width: 44px;
      margin: 0 0 14px -25px;
      left: 53%;
    }
  }
  .finish-line {
    z-index: 10;
    position: fixed;
    right: 2vw;
    bottom: 40px;
    width: 20px;
    transition: 1s;
    &.running {
      right: 5vw;
    }
    &.finish {
      right: 55vw;
    }
  }
  .line {
    position: absolute;
    top: 41px;
    width: 102vw;
    height: 3px;
    background-image: linear-gradient(to right, #fff 0%, #fff 50%, transparent 50%);
    background-size: 30px 3px;
    background-repeat: repeat-x;
    opacity: .4;
    &.move {
      animation: move 1s linear infinite;
    }
  }
}

@media (min-width: 768px) {
  footer {
    height: 100px;
    .copyright {
      gap: 15px;

      .copyright-group1 {
        margin-bottom: 0px;
      }

      flex-direction: row;
    }
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hidden {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes move {
  0% {
    margin-left: 0;
  }
  99% {
    margin-left: -30px;
  }
  100% {
    margin-left: 0;
  }
}

@media (min-width: 800px) {
  main .part {
    width: 540px;
    height: 65vh;
    min-height: 480px;
    margin: 0 auto;
    text-align: left;
    position: relative;
    .screenshot {
      position: absolute;
      right: 0;
      top: -120px;
    }
    &.one {
      margin-top: 25vh;
      .screenshot {
        top: -80px;
      }
    }
    &.four {
      height: 50vh;
      min-height: 400px;
    }
    &.five {
      text-align: center;
      height: 200px;
      min-height: 200px;
    }
  }
}
</style>
