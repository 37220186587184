export const PART_ONE = {
  zh: {
    sports: ['运动', '跑步', '走路', '骑行', 'HIIT', '普拉提', '攀岩', '腰旗橄榄球', '篮球', '网球'],
    title: '记录',
    desc: '80+ 种类',
  },
  en: {
    sports: ['Exercise', 'Running', 'Walking', 'Cycling', 'HIIT', 'Pilates', 'Climbing', 'Rugby', 'Basketball', 'Tennis'],
    title: 'Tracker',
    desc: '80+ sports',
  },
  fr: {
    sports: ['Exercice', 'Course à pied', 'Marche', 'Cyclisme', 'HIIT', 'Pilates', 'Escalade', 'Rugby', 'Basket-ball', 'Tennis'],
    title: 'Suivi',
    desc: '80+ sports',
  },
  de: {
    sports: ['Training', 'Laufen', 'Gehen', 'Radfahren', 'HIIT', 'Pilates', 'Klettern', 'Rugby', 'Basketball', 'Tennis'],
    title: 'Tracker',
    desc: 'Über 80 Sportarten',
  },
  it: {
    sports: ['Esercizio', 'Corsa', 'Camminata', 'Ciclismo', 'HIIT', 'Pilates', 'Arrampicata', 'Rugby', 'Pallacanestro', 'Tennis'],
    title: 'Tracker',
    desc: 'Oltre 80 sport',
  },
  es: {
    sports: ['Ejercicio', 'Carrera', 'Caminata', 'Ciclismo', 'HIIT', 'Pilates', 'Escalada', 'Rugby', 'Baloncesto', 'Tenis'],
    title: 'Rastreador',
    desc: 'Más de 80 deportes',
  },
  pt: {
    sports: ['Exercício', 'Corrida', 'Caminhada', 'Ciclismo', 'HIIT', 'Pilates', 'Escalada', 'Rugby', 'Basquetebol', 'Ténis'],
    title: 'Rastreador',
    desc: 'Mais de 80 esportes',
  },
  ja: {
    sports: ['運動', 'ランニング', 'ウォーキング', 'サイクリング', 'HIIT', 'ピラティス', 'クライミング', 'ラグビー', 'バスケットボール', 'テニス'],
    title: 'トラッカー',
    desc: '80種類以上',
  },
  ko: {
    sports: ['운동', '달리기', '걷기', '자전거 타기', 'HIIT', '필라테스', '암벽 등반', '럭비', '농구', '테니스'],
    title: '트래커',
    desc: '80가지 이상',
  },
  ru: {
    sports: ['Упражнения', 'Бег', 'Ходьба', 'Велоспорт', 'HIIT', 'Пилатес', 'Скалолазание', 'Регби', 'Баскетбол', 'Теннис'],
    title: 'Трекер',
    desc: 'Более 80 видов спорта',
  },
  'zh-HK': {
    sports: ['運動', '跑步', '走路', '單車', 'HIIT', '普拉提', '攀岩', '足球', '籃球', '網球'],
    title: '記錄',
    desc: '80+ 類別',
  },
  'zh-TW': {
    sports: ['運動', '跑步', '走路', '自行車', 'HIIT', '普拉提', '攀岩', '足球', '籃球', '網球'],
    title: '追蹤器',
    desc: '80+ 種運動',
  },
};

export const PART_TWO = {
  zh: {
    title: '每月回顾',
    desc: '自动同步',
  },
  en: {
    title: 'Monthly Recap',
    desc: 'Auto sync',
  },
  fr: {
    title: 'Récapitulatif mensuel',
    desc: 'Synchronisation automatique',
  },
  de: {
    title: 'Monatliche Zusammenfassung',
    desc: 'Automatische Synchronisierung',
  },
  it: {
    title: 'Riepilogo mensile',
    desc: 'Sincronizzazione automatica',
  },
  es: {
    title: 'Resumen mensual',
    desc: 'Sincronización automática',
  },
  pt: {
    title: 'Resumo mensal',
    desc: 'Sincronização automática',
  },
  ja: {
    title: '月次レポート',
    desc: '自動同期',
  },
  ko: {
    title: '월별 요약',
    desc: '자동 동기화',
  },
  ru: {
    title: 'Ежемесячный отчет',
    desc: 'Автоматическая синхронизация',
  },
  'zh-HK': {
    title: '每月回顧',
    desc: '自動同步',
  },
  'zh-TW': {
    title: '每月回顧',
    desc: '自動同步',
  },
};

export const PART_THREE = {
  zh: {
    title: '好友分享',
    desc: '保持动力、养成习惯',
  },
  en: {
    title: 'Share With Friends ',
    desc: 'Stay motivated',
  },
  fr: {
    title: 'Partager avec des amis',
    desc: 'Restez motivé',
  },
  de: {
    title: 'Mit Freunden teilen',
    desc: 'Bleiben Sie motiviert',
  },
  it: {
    title: 'Condividi con gli amici',
    desc: 'Rimani motivato',
  },
  es: {
    title: 'Comparte con amigos',
    desc: 'Mantente motivado',
  },
  pt: {
    title: 'Compartilhe com amigos',
    desc: 'Mantenha-se motivado',
  },
  ja: {
    title: '友達と共有',
    desc: 'モチベーションを維持',
  },
  ko: {
    title: '친구와 공유',
    desc: '동기 부여 유지',
  },
  ru: {
    title: 'Поделиться с друзьями',
    desc: 'Оставайтесь мотивированными',
  },
  'zh-HK': {
    title: '好友分享',
    desc: '保持動力、養成習慣',
  },
  'zh-TW': {
    title: '好友分享',
    desc: '保持動力、養成習慣',
  },
};

export const PART_FOUR = {
  zh: {
    title: '专业数据',
    desc: '分析和提升运动能力',
  },
  en: {
    title: 'Professional Stats',
    desc: 'Improve performance',
  },
  fr: {
    title: 'Statistiques professionnelles',
    desc: 'Améliorer les performances',
  },
  de: {
    title: 'Professionelle Statistiken',
    desc: 'Leistung verbessern',
  },
  it: {
    title: 'Statistiche professionali',
    desc: 'Migliora le prestazioni',
  },
  es: {
    title: 'Estadísticas profesionales',
    desc: 'Mejora el rendimiento',
  },
  pt: {
    title: 'Estatísticas profissionais',
    desc: 'Melhore o desempenho',
  },
  ja: {
    title: 'プロの統計',
    desc: 'パフォーマンスを向上させる',
  },
  ko: {
    title: '전문 통계',
    desc: '성과 향상',
  },
  ru: {
    title: 'Профессиональная статистика',
    desc: 'Повышение производительности',
  },
  'zh-HK': {
    title: '專業數據',
    desc: '分析和提升運動能力',
  },
  'zh-TW': {
    title: '專業數據',
    desc: '分析和提升運動能力',
  },
};

export const PART_FIVE = {
  zh: {
    title: '马上开始使用 Fito',
  },
  en: {
    title: 'Start Fito Now',
  },
  fr: {
    title: 'Commencez à utiliser Fito maintenant',
  },
  de: {
    title: 'Jetzt Fito starten',
  },
  it: {
    title: 'Inizia a usare Fito ora',
  },
  es: {
    title: 'Comienza a usar Fito ahora',
  },
  pt: {
    title: 'Comece a usar Fito agora',
  },
  ja: {
    title: '今すぐFitoを使い始めましょう',
  },
  ko: {
    title: '지금 바로 Fito 사용 시작',
  },
  ru: {
    title: 'Начните использовать Fito прямо сейчас',
  },
  'zh-HK': {
    title: '馬上開始使用 Fito',
  },
  'zh-TW': {
    title: '馬上開始使用 Fito',
  },
};